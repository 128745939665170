

export default function Question({ questionsList, onChange }) {

    return (
        <>
            <div className="question-container">
                <h1>{questionsList.text}</h1>
                <div className="row">
                    {questionsList.possibilities.map((possibility, index) => (
                        <div key={index} className="option">
                            <input type="radio" name="radio" onChange={onChange} id={index} unchecked="true" />
                            <label htmlFor={index}>{possibility.answer}{" "}</label>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}