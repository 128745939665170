import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client"
import App from "./components/App.jsx";

const root = createRoot(document.getElementById('root'));
root.render(<App />);

//Potential Features:
//add option to go back and forth between the questions
//clear/reset the radio button selection when next question is rendered
//ask player name before starting and save their and other players score (display it at the end)