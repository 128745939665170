import { useRef, useState } from "react";
import { quizQuestions, correctAnswers } from "../quiz.js";
import FinalScore from "./FinalScore.jsx";
import Styles from "../styles/App.css";
import Question from "./Question.jsx";


export default function App() {

    let index = useRef(0);
    let selectedOption = useRef(null);
    let playerAnswers = useRef([]);
    const [question, setQuestion] = useState(quizQuestions[index.current]);
    const [finish, setFinish] = useState(true);
    const [showSubmit, setShowSubmit] = useState(false);
    const [score, setScore] = useState(0);
    const [isComplete, setIsComplete] = useState(null);


    function handleClick() {
        if (selectedOption.current != null) {
            index.current += 1;
            playerAnswers.current = [...playerAnswers.current, selectedOption.current];
            console.log(playerAnswers.current);
            if (index.current < quizQuestions.length) {
                setQuestion(quizQuestions[index.current]);
            }
            else {
                setFinish(false);
                setShowSubmit(true);
            }
            console.log(question, index.current);
        }
        else {
            alert("You thought you could get away without selecting an option. HAHAHAHAHAHHA (Evil Laughs)")
        }
    }

    function handleChange(event) {
        selectedOption.current = parseInt(event.target.id);
    }

    function handleSubmit() {

        setShowSubmit(false);
        let playerScore = 0;

        // Check that both playerAnswers and correctAnswers have the same length
        if (playerAnswers.current.length === correctAnswers.length) {
            for (let i = 0; i < playerAnswers.current.length; i++) {
                if (playerAnswers.current[i] === correctAnswers[i]) {
                    playerScore += 1;
                } else {
                    console.log("Skipped");
                }
            }
        } else {
            console.log("Length mismatch between playerAnswers and correctAnswers.");
        }

        setScore(playerScore);


        setIsComplete(true);
        // const decision = JSON.stringify(playerAnswers.current) === JSON.stringify(correctAnswers);
        // if (decision === true) {
        //     setIsComplete(true);
        // }
        // else {
        //     setIsComplete(false);
        // }
    }

    return (
        <>
            {finish && (
                <div className="container">

                    <img src="/friends-logo.png" width="700px" alt="lol" />

                    <Question questionsList={question} onChange={handleChange} />

                    <button onClick={handleClick}>Next</button>
                </div>
            )}

            {!finish && showSubmit && <div className="container"><button onClick={handleSubmit}>Submit?</button></div>}

            {isComplete === true && <div className="container"><FinalScore points={score} /></div>}
        </>
    );

}